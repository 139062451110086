import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import { createApp }  from "vue";
import router from "./router";
import themes from "devextreme/ui/themes";

import App from "./App";
import appInfo from "./app-info";


import { licenseKey } from './devextreme-license';
import Config from "devextreme/core/config";

Config({ licenseKey });

// import Form from "devextreme/ui/form";
// import Config from "devextreme/core/config";


// Config({
//     forceIsoDateParsing: false,
//     // editorStylingMode: 'outlined',
// });
// //
// Form.defaultOptions({
//     device: [
//         { deviceType: 'desktop' },
//         { deviceType: 'tablet' },
//         { deviceType: 'phone' },
//     ],
//     options: {
//         // labelMode: 'floating',
//         labelAlign: 'left'
//     }
// });

themes.initialized(() => {

    const app = createApp(App);
    app.use(router);
    app.config.globalProperties.$appInfo = appInfo;
    app.mount('#app');
});
