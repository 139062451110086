import axios from "axios";
import appConfig from "@/app-config";

export default {
    _error: null,

    loggedIn() {
        return !!appConfig.token;
    },

    logIn(loginName, password) {
        return axios.post(appConfig.apiHost +  "login", {loginname: loginName, pw: password}).then(response => {
            if (response.status === 200) {
                localStorage.setItem('token', response.data.session_token);
                appConfig.menu = response.data.menu;
                appConfig.userIdent = response.data.user_ident;
                appConfig.userId = response.data.user_id;
                appConfig.userGroupId = response.data.user_group_id;
                appConfig.token = response.data.session_token;
                appConfig.dashboardReportId = response.data.dashboard_report_id;
                return {
                    isOk: true
                }
            }
        }).catch(error => {
            this._error = error;
            return {
                isOk: false
            }
        });
    },

    async logOut() {
        appConfig.menu = null;
        appConfig.userIdent = null;
        appConfig.userId = null;
        appConfig.token = null;
        localStorage.removeItem('token');
    },

    async authenticate() {
        const token = localStorage.getItem('token');
        if (!token) return false;
        return await axios.get(appConfig.apiHost + "authenticate?session_token=" + token).then(response => {
            localStorage.setItem('token', response.data.session_token);
            appConfig.menu = response.data.menu;
            appConfig.userIdent = response.data.user_ident;
            appConfig.userId = response.data.user_id;
            appConfig.userGroupId = response.data.user_group_id;
            appConfig.token = response.data.session_token;
            appConfig.dashboardReportId = response.data.dashboard_report_id;
            return response.status === 200;
        }).catch(error => {
            this._error = error;
            return null;
        });
    },

    changePw(pw) {
        return axios.post(appConfig.apiHost +  "changePW", {id: appConfig.userId, pw: pw}).then(response => {
            if (response.status === 200) {
                return {
                    isOk: true
                }
            }
        }).catch(error => {
            this._error = error;
            return {
                isOk: false
            }
        });
    }
};
