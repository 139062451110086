
import axios from "axios";
import {formatDate} from "devextreme/localization";

export default {

    version: '10014',
    language: 'de',

    googleApi: 'AIzaSyBoGEsyYZ30VYxAm7j0vQFS1kD43s-WhlM',

    token: null,
    userId: null,
    userGroupId: null,
    userIdent: null,
    dashboardReportId: null,

    menu: [],

    host: ((document.location.origin === 'http://localhost:8080') || (document.location.origin === 'http://localhost:8081')) ? 'http://kw/' : 'https://biportal.kopierer-welt.de/',
    apiHost: ((document.location.origin === 'http://localhost:8080') || (document.location.origin === 'http://localhost:8081')) ? 'http://kw/api/' : 'https://biportal.kopierer-welt.de/api/',

    sendRequest(className, id, attr, value) {
        return new Promise((resolve) => {
            return axios.post(this.apiHost + "validation", {
                className: className,
                id: id,
                attribute: attr,
                value: value
            }, {
                headers: {
                    'Authorization': 'Bearer ' + this.session_token,
                    'last-update-ts': formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
                }
            }).then(response => {
                if (response.status === 200) {
                    resolve(true);
                }
            }).catch(() => {
                resolve(false);
            });
        });
    },

    updateHeight(component) {
        if (component)
            if (document.body.clientWidth > 800)
            component.instance.option("height", ((window.innerHeight) - 76));
        else
            component.instance.option("height", ((window.innerHeight) - 56));
        else
            return window.innerHeight - 44;
    }


};
