<template>
    <header class="header-component">
        <DxToolbar class="header-toolbar">
            <DxItem :visible="menuToggleEnabled" location="before" css-class="menu-button">
                <template #default>
                    <dx-button icon="menu" styling-mode="text" @click="toggleMenuFunc"/>
                </template>
            </DxItem>
            <DxItem css-class="header-title dx-toolbar-label" location="before">
                <div class="header-title logo"><img height="24" width="150" src="assets/02_Logo_CMYK.svg"></div>
            </DxItem>
            <DxItem location="after">
                <DxDropDownButton
                    :items="[
                        {id:1, name: formatMessage('btnChangePassword'), icon:'assets/keys.svg'},
                        {id:2, name:formatMessage('btnLogout'), icon:'assets/door_exit.svg'}
                     ]"
                    :drop-down-options="{ width: 210 }"
                    :text="userIdent"
                    styling-mode="text"
                    icon="assets/user.svg"
                    display-expr="name"
                    key-expr="id"
                    @item-click="onUserMenuClick"
                />
            </DxItem>
        </DxToolbar>
    </header>
    <DxPopup
        v-model:visible="changePasswordVisible"
        :drag-enabled="false"
        :hide-on-outside-click="true"
        :show-close-button="true"
        :show-title="true"
        :width="320"
        :height="300"

        :title="formatMessage('cpChangePassword')"
    >
        <DxForm id="form" ref="form" label-mode="floating" :form-data="formData" :col-count="1">
            <DxSimpleItem data-field="pw" :is-required="true"
                          :editor-options="{label: formatMessage('cpNewPassword'), showClearButton: true, mode: 'password'}"/>
            <DxSimpleItem data-field="retypedPw" :is-required="true"
                          :editor-options="{label: formatMessage('cpNewPassword'), showClearButton: true, mode: 'password'}"/>
        </DxForm>
        <DxToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="center"
            :options="{text: formatMessage('btnSave')}"
            @click="onChangePWClick"
        />
        <DxToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="center"
            :options="{text:  formatMessage('btnCancel')}"
            @click="onCancelClick"
        />
    </DxPopup>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxToolbar, {DxItem} from "devextreme-vue/toolbar";
import auth from "../auth";
import appConfig from "../app-config";
import {useRouter, useRoute} from 'vue-router';
import {DxDropDownButton} from "devextreme-vue";
import {confirm} from "devextreme/ui/dialog"
import {DxPopup, DxToolbarItem} from "devextreme-vue/popup";
import {DxForm, DxSimpleItem} from "devextreme-vue/form";
import {alert} from "devextreme/ui/dialog";
import {formatMessage} from "devextreme/localization";

export default {
    props: {
        menuToggleEnabled: Boolean,
        title: String,
        toggleMenuFunc: Function,
        logOutFunc: Function
    },

    data() {
        const router = useRouter();
        const route = useRoute();

        return {
            userIdent: appConfig.userIdent,
            changePasswordVisible: false,
            formData: {pw: '', retypedPw: ''},
            router,
            route
        };
    },

    methods: {
        formatMessage,
        onUserMenuClick(e) {
            switch (e.itemData.id) {
                case 1:
                    this.formData = {pw: '', retypedPw: ''}
                    this.changePasswordVisible = true;
                    break;
                case 2:
                    confirm(formatMessage('cpWantLogout'), 'Logout').then((dialogResult) => {
                        if (dialogResult) {
                            auth.logOut();
                            this.router.push({
                                path: "/authentication",
                                query: {redirect: "/home"}
                            });
                        }
                    });
                    break;
            }
        },

        onChangePWClick: async function () {
            const {pw, retypedPw} = this.formData;
            const validationResult = this.$refs.form.instance.validate();

            if (validationResult.isValid) {

                if (pw === retypedPw) {
                    const result = await auth.changePw(pw);
                    await alert(result.isOk ? formatMessage('msgPassWordChangeSuccess') : formatMessage('msgPassWordChangeFailed'), formatMessage('cpInformation'));
                    this.changePasswordVisible = false;
                    return;
                } else
                    await alert(formatMessage('msgRepetitionWrong'), formatMessage('cpInformation'));
                return;
            }
            await alert(formatMessage('msgCheckInput'), formatMessage('cpInformation'));
        },

        onCancelClick() {
            this.changePasswordVisible = false;
        }
    },

    components: {
        DxButton,
        DxToolbar,
        DxItem,
        DxDropDownButton,
        DxToolbarItem,
        DxPopup,
        DxForm,
        DxSimpleItem
    }
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
@import "../dx-styles.scss";
.header-component {
    flex: 0 0 auto;
    z-index: 1;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 0 rgba(0, 0, 0, 0.24);

    .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
        color: $base-accent;
    }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
    padding: 0 20px;

    .screen-x-small & {
        padding: 0 20px;
    }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
    width: $side-panel-min-width;
    text-align: center;
    padding: 0;
}

.header-title .DxItem-content {
    padding: 0;
    margin: 0;
}

.header-title .logo {
    padding-left: 15px;
    padding-top: 2px;
}
</style>
